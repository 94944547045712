/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import Moment from "react-moment";
interface DateProps {
  children: string;
}

export const Date = ({ children }: DateProps) => (
  <Moment format="YYYY.MM.DD HH:MM">{children}</Moment>
);
