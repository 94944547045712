/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { useOrganizationsQuery } from "../../generated/graphql";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface OrganizationParams {
  orgAccountId: string;
}

export const Organization = ({ orgAccountId }: OrganizationParams) => {
  const { logout } = useAuth0();
  const { data, loading } = useOrganizationsQuery();
  return (
    <>
      {!loading && (
        <div className="navbar-end">
          <div className="navbar-item has-dropdown is-hoverable">
            <span className="navbar-link">
              {
                data?.accessibleOrganizations?.find(
                  org => org?.orgAccountId === orgAccountId
                )?.name
              }
            </span>
            <div className="navbar-dropdown is-right ">
              {data?.accessibleOrganizations.map(org => (
                <Link to={`/${org?.orgAccountId}`} className="navbar-item">
                  {org?.name}
                </Link>
              ))}
              <hr className="navbar-divider " />
              <span className="navbar-item" onClick={() => logout({ returnTo: process.env.REACT_APP_REDIRECT })}>
                Logout
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
