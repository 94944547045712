/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useMemo, useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { useTemplatesQuery, Maybe, Template } from "../../generated/graphql";
import { Arrow, Date } from "../../components/UI";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

type TParams = { orgId: string };

const TemplatesByName = (
  templates: Array<Maybe<Pick<Template, "name">>> | undefined | null
) =>
  templates
    ? templates.reduce((byName: any, bl: any) => {
        if (bl?.name && byName[bl.name]) {
          byName[bl.name].push(bl);
          return byName;
        }
        byName[bl.name] = [bl];
        return byName;
      }, {})
    : {};

interface TemplateVersionsProps {
  templates: Array<any>;
  orgAccountId: string;
  push: any;
}

const TemplateVersions = ({
  templates,
  orgAccountId,
  push
}: TemplateVersionsProps) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      {templates.length === 1 && (
        <tr>
          <th></th>
          {<th>{templates[0]?.name}</th>}
          <td>
            <Link
              to={`/${orgAccountId}/templates/${encodeURIComponent(
                templates[0].name
              )}/${encodeURIComponent(templates[0].version)}`}
            >
              {templates[0]?.version}
            </Link>
          </td>

          <td>
            <Date>{templates[0]?.createdAt}</Date>
          </td>
          <td>
            <Date>{templates[0]?.updatedAt}</Date>
          </td>
        </tr>
      )}
      {templates.length > 1 && (
        <tr
          className={`expandable`}
          onClick={e => {
            e.preventDefault();

            setShowAll(!showAll);
          }}
        >
          <th>
            <a href="#t">
              <Arrow down={showAll} />
            </a>
          </th>
          <th>{templates[0]?.name}</th>
          <td colSpan={3}>{templates.length} versions</td>
        </tr>
      )}
      {templates.length > 1 &&
        showAll &&
        templates.map((template: any, index: number) => (
          <>
            <tr
              onClick={e => {
                e.preventDefault();

                push(
                  `/${orgAccountId}/templates/${encodeURIComponent(
                    template.name
                  )}/${encodeURIComponent(template.version)}`
                );
              }}
            >
              <th></th>
              <th></th>
              <td>
                <Link
                  to={`/${orgAccountId}/templates/${encodeURIComponent(
                    template.name
                  )}/${encodeURIComponent(template.version)}`}
                >
                  {template?.version}
                </Link>
              </td>
              <td>
                <Date>{template?.createdAt}</Date>
              </td>
              <td>
                <Date>{template?.updatedAt}</Date>
              </td>
            </tr>
          </>
        ))}
    </>
  );
};

export const Templates = ({ match, history }: RouteComponentProps<TParams>) => {
  const { push } = history;
  const { orgId: orgAccountId } = match.params;
  const { data, loading } = useTemplatesQuery({
    variables: { orgAccountId }
  });

  const filtered = useMemo(
    () => (data?.templates ? TemplatesByName(data?.templates) : {}),
    [data]
  );

  return (
    <div>
      <Breadcrumbs
        list={[
          { label: "Organization", uri: `/${orgAccountId}` },
          { label: "Templates", uri: `/${orgAccountId}/templates` }
        ]}
      />
      <h1 className="title">Templates</h1>
      {loading && <>Loading</>}

      {!loading && data?.templates && (
        <div className="box">
          <table className="table is-fullwidth is-striped is-clickable">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Version</th>
                <th>Created at</th>
                <th>Updated at</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(filtered).map(blName => {
                const templates = filtered[blName];
                return (
                  <TemplateVersions
                    orgAccountId={orgAccountId}
                    templates={templates.sort(
                      (a: any, b: any) => b.version - a.version
                    )}
                    push={push}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
