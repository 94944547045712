/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useAccountQuery } from "../../generated/graphql";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Date } from "../../components/UI";
import { Meta } from "../../components/Meta/Meta";

type TParams = { orgId: string; accountId: string };

export const Account = ({ match, history }: RouteComponentProps<TParams>) => {
  const { orgId: orgAccountId, accountId } = match.params;
  const { data, loading } = useAccountQuery({
    variables: {
      orgAccountId,
      id: decodeURIComponent(accountId)
    }
  });

  return (
    <div>
      <Breadcrumbs
        list={[
          { label: "Organization", uri: `/${orgAccountId}` },
          { label: "Accounts", uri: `/${orgAccountId}/accounts` },
          { label: `${data?.accountByAccountId?.name}`, uri: `` }
        ]}
      />
      {loading && <>Loading</>}
      <h1 className="title">{data?.accountByAccountId?.name}</h1>
      <h2 className="subtitle">Account details</h2>
      <div className="box">
        <table className="table is-fullwidth">
          <tbody>
            <tr>
              <th>Aws account ID</th>
              <td>{data?.accountByAccountId?.awsAccountId}</td>
            </tr>
            <tr>
              <th>Account alias</th>
              <td>{data?.accountByAccountId?.accountAlias}</td>
            </tr>
            <tr>
              <th>Attached email</th>
              <td>{data?.accountByAccountId?.email}</td>
            </tr>
            <tr>
              <th>Organizational Unit</th>
              <td>{data?.accountByAccountId?.organizationalUnit?.name}</td>
            </tr>
            <tr>
              <th>Creation status</th>
              <th>
                <span
                  className={`tag ${
                    data?.accountByAccountId?.creationStatus?.status ===
                    "succeeded"
                      ? "is-success"
                      : "is-warning"
                  }`}
                >
                  {data?.accountByAccountId?.creationStatus?.status}
                </span>
                {data?.accountByAccountId?.creationStatus?.error && (
                  <p>{data?.accountByAccountId?.creationStatus?.error}</p>
                )}
              </th>
            </tr>
            <tr>
              <th>Created At</th>
              <td>
                <Date>{data?.accountByAccountId?.createdAt || ""}</Date>
              </td>
            </tr>
            <tr>
              <th>Updated at</th>
              <td>
                <Date>{data?.accountByAccountId?.updatedAt || ""}</Date>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {data?.accountByAccountId?.access && (
        <>
          <h3 className="title is-size-5">Access</h3>
          <div className="box">
            <table className="is-fullwidth is-striped table">
              <thead>
                <tr>
                  <th>Role ARN</th>
                  <th>External ID</th>
                </tr>
              </thead>
              <tbody>
                {data?.accountByAccountId?.access?.map(access => (
                  <tr>
                    <td>{access.roleArn}</td>
                    <td>{access.externalId}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {data?.accountByAccountId?.metadata && (
        <Meta metadata={data?.accountByAccountId?.metadata} />
      )}
    </div>
  );
};
