/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { useDeploymentsQuery, Maybe } from "../../generated/graphql";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

type TParams = { orgId: string };

const TagClassName = (status: Maybe<string>) => {
  switch (status) {
    case "completed":
      return "is-success";
    case "failed":
      return "is-danger";
    default:
      return "";
  }
};

export const Deployments = ({
  match,
  history
}: RouteComponentProps<TParams>) => {
  const { orgId: orgAccountId } = match.params;
  const { data, loading } = useDeploymentsQuery({
    variables: { orgAccountId }
  });

  return (
    <div>
      <Breadcrumbs
        list={[
          { label: "Organization", uri: `/${orgAccountId}` },
          { label: "Deployments", uri: `/${orgAccountId}/` }
        ]}
      />
      <h1 className="title">Deployments</h1>
      {loading && <>Loading</>}

      {!loading && data?.deployments && (
        <div className="box">
          <table className="table is-fullwidth is-striped is-clickable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Blueprints</th>
                <th></th>
                <th>Accounts</th>
                <th>Provisionings</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.deployments.map(deployment => (
                <tr>
                  <td>
                    <strong>{deployment.name}</strong>
                  </td>
                  <td>
                    <ul>
                      {deployment.provisionings
                        .reduce((blueprints: any, pr) => {
                          blueprints.push(pr.blueprint);
                          return blueprints;
                        }, [])
                        .map((blueprint: any) => (
                          <li>
                            <Link
                              to={`/${orgAccountId}/blueprints/${blueprint.name}/${blueprint.version}`}
                              className="table-link"
                            >
                              {blueprint.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {deployment.provisionings.map(p =>
                        p.account.awsAccountId ? (
                          <li>
                            <span role="img" aria-label="arrow">
                              ➡️
                            </span>
                          </li>
                        ) : (
                          <></>
                        )
                      )}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {deployment.provisionings
                        .reduce((accounts: any, pr) => {
                          accounts.push(pr.account);
                          return accounts;
                        }, [])
                        .map((account: any) => (
                          <li>
                            <Link
                              to={`/${orgAccountId}/accounts/${account.awsAccountId}/`}
                              className="table-link"
                            >
                              {account.name} ({account.awsAccountId})
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </td>
                  <td>
                    {
                      deployment.provisionings.filter(
                        pr => pr.state.status === "completed"
                      ).length
                    }{" "}
                    / {deployment.provisionings.length}
                  </td>
                  <td>
                    <span className={`tag ${TagClassName(deployment.status)}`}>
                      {deployment.status.toUpperCase()}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
