/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
import { Maybe } from "../generated/graphql";

type User = {
  email: Maybe<String>;
};

interface UserContextInterface {
  user: Maybe<User>;
  update(user: User): any;
}

const UserContext = React.createContext<UserContextInterface>({
  user: null,
  update: () => {}
});

interface UserProviderProps {
  children: ReactNode;
  email: String;
}

class UserProvider extends React.Component<UserProviderProps, User> {
  state: Readonly<User> = {
    email: this.props.email
  };

  setUser = (user: User) => {
    this.setState(user);
  };

  render() {
    const user = this.state;
    const { children } = this.props;
    
    return (
      <UserContext.Provider value={{ user, update: this.setUser }}>
        {children}
      </UserContext.Provider>
    );
  }
}

export { UserProvider, UserContext };
