/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { ReactNode } from "react";
interface AppProps {
  children: ReactNode;
}

export const App = ({ children }: AppProps) => (
  <>
    <div className="main wide">
      <div className="container is-fluid container-padding ">{children}</div>
    </div>
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <strong>Nordcloud Maestro</strong> by Nordcloud. © 2019.
        </p>
      </div>
    </footer>
  </>
);

export default App;
