/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useBlueprintQuery } from "../../generated/graphql";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Meta } from "../../components/Meta/Meta";

type TParams = { orgId: string; name: string; version: string };

interface BlueprintName {
  name: string;
}

export const Blueprint = ({ match, history }: RouteComponentProps<TParams>) => {
  const { push } = history;
  const { orgId: orgAccountId, name, version } = match.params;
  const { data, loading } = useBlueprintQuery({
    variables: {
      orgAccountId,
      name: decodeURIComponent(name),
      version: decodeURIComponent(version)
    }
  });

  return (
    <div>
      <Breadcrumbs
        list={[
          { label: "Organization", uri: `/${orgAccountId}` },
          { label: "Blueprints", uri: `/${orgAccountId}/blueprints` },
          { label: `${data?.blueprint?.name}`, uri: `` },
          { label: `${data?.blueprint?.version}`, uri: `` }
        ]}
      />
      {loading && <>Loading</>}
      <h1 className="title">{data?.blueprint?.name}</h1>
      <h1 className="subtitle">{data?.blueprint?.version}</h1>

      <div>
        <h3 className="title is-size-5">Templates</h3>
        <div className="box">
          <table className="table is-fullwidth is-clickable ">
            <thead>
              <tr>
                <th>Name</th>
                <th>Version</th>
                <th>Depends on</th>
              </tr>
            </thead>
            <tbody>
              {data?.blueprint?.templates?.map(template => (
                <tr
                  onClick={e => {
                    e.preventDefault();
                    push(
                      `/${orgAccountId}/templates/${encodeURIComponent(
                        template.name
                      )}/${encodeURIComponent(template.version)}`
                    );
                  }}
                >
                  <td>{template.name}</td>
                  <td>{template.version}</td>
                  <td>
                    {template.dependsOn?.map(dep => (
                      <span>
                        {dep.name} {dep.version}
                      </span>
                    ))}
                    {!template.dependsOn && <>-</>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {data?.blueprint?.metadata && (
        <>
          <hr />
          <Meta metadata={data?.blueprint?.metadata} />
        </>
      )}
    </div>
  );
};
