/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useState, useEffect } from "react";
import { UserProvider } from "../../context/user";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  children: JSX.Element;
}

const Login: React.FC<Props> = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");

  const {
    user, 
    isAuthenticated,
    loginWithRedirect,
    isLoading,
  } = useAuth0();

  const checkCurrentUser = () => {
    if (isLoading) {
      return
    }

    if (isAuthenticated) {
      setEmail(user.email)
      setLoggedIn(true)
    } else {
      loginWithRedirect()
    }
  };

  useEffect(() => checkCurrentUser());
  return loggedIn ? (
    <UserProvider email={email}>{children}</UserProvider>
  ) : (
    <div />
  );
};

export default Login;
