/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import Login from "./components/Auth/Auth";
import { SelectOrganization } from "./views/Organization/organization";
import { Structure } from "./views/Structure/Page";
import { Blueprints } from "./views/Blueprints/Page";
import { Deployments } from "./views/Deployments/Page";
import { Blueprint } from "./views/Blueprint/Page";
import { Template } from "./views/Template/Page";
import { Templates } from "./views/Templates/Page";
import { Account } from "./views/Account/Page";
import { Navigation } from "./components/Nav/Nav";
import "./assets/scss/index.scss";
import "font-awesome/css/font-awesome.css";

import App from "./App";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";

interface ApolloProps {
  children: JSX.Element;
}

const Apollo: React.FC<ApolloProps> = ({ children }) => {

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
  });

  const { getIdTokenClaims } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    const token = await getIdTokenClaims().then(c => c.__raw)
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token
      }
    };
  });
  
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache"
      },
      watchQuery: {
        fetchPolicy: "no-cache"
      }
    }
  });
  
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        {children}
      </ApolloHooksProvider>
    </ApolloProvider>
  )
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_OAUTH_DOMAIN || ""}
    clientId={process.env.REACT_APP_OAUTH_CLIENT_ID || ""}
    redirectUri={process.env.REACT_APP_REDIRECT || ""}
    useRefreshTokens={true}
    cacheLocation="localstorage"      
  >
    <Apollo>
      <Login>
        <Router>
          <>
            <Route path="/:orgId" component={Navigation} />
            <App>
              <Route exact path="/" component={SelectOrganization} />
              <Route exact path="/:orgId/" component={Deployments} />
              <Route exact path="/:orgId/structure" component={Structure} />
              <Route
                exact
                path="/:orgId/accounts/:accountId"
                component={Account}
              />
              <Route exact path="/:orgId/blueprints" component={Blueprints} />
              <Route
                exact
                path="/:orgId/blueprints/:name/:version"
                component={Blueprint}
              />
              <Route exact path="/:orgId/templates" component={Templates} />
              <Route
                exact
                path="/:orgId/templates/:name/:version"
                component={Template}
              />
            </App>
          </>
        </Router>
      </Login>
    </Apollo>
  </Auth0Provider>,
  document.getElementById("root")
);
