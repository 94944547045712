/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useState } from "react";
import {
  useOrganizationalUnitsQuery,
  OrganizationalUnit,
  Account,
  Maybe
} from "../../generated/graphql";
import { RouteComponentProps } from "react-router-dom";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

type TParams = { orgId: string };

interface UnitProps {
  ou: Maybe<Pick<OrganizationalUnit, "id" | "name" | "children" | "accounts">>;
  push: any;
}

interface AccountProps {
  account: Maybe<
    Pick<Account, "email" | "awsAccountId" | "accountAlias" | "name">
  >;
  push: any;
}

const SingleAccount = ({ account, push }: AccountProps) => {
  return (
    <tr
      onClick={e => {
        e.preventDefault();
        push(account?.awsAccountId);
      }}
    >
      <td>{account?.name}</td>
      <td>{account?.awsAccountId}</td>
      <td>{account?.accountAlias}</td>
      <td>{account?.email}</td>
    </tr>
  );
};

const Unit = ({ ou, push }: UnitProps) => {
  const [show, setShow] = useState(false);
  return (
    <li>
      <h1 className="title is-size-4">{ou?.name}</h1>
      {!ou?.accounts && (
        <h2 className="subtitle is-size-7">
          <i>No accounts in this OU</i>
        </h2>
      )}
      {ou?.accounts && (
        <>
          <h2
            className="subtitle clickable is-size-6"
            onClick={() => setShow(!show)}
          >
            {!show ? <>Show</> : <>Hide</>} accounts ({ou?.accounts?.length})
          </h2>
          {show && (
            <table className="table is-striped is-fullwidth is-clickable">
              <thead>
                <tr>
                  <th>Account name</th>
                  <th>AWS Account ID</th>
                  <th>Alias</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {ou.accounts.map(acc => (
                  <SingleAccount account={acc} push={push} />
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
      <>
        {ou?.children && (
          <ul>
            {ou.children.map(ou => (
              <Unit ou={ou} push={push} />
            ))}
          </ul>
        )}
      </>
    </li>
  );
};

export const Structure = ({ match, history }: RouteComponentProps<TParams>) => {
  const { orgId: orgAccountId } = match.params;
  const { push } = history;
  const pushAccount = (accountId: string) =>
    push(`/${orgAccountId}/accounts/${accountId}`);
  const { data, loading } = useOrganizationalUnitsQuery({
    variables: { orgAccountId, name: "root" }
  });

  return (
    <div>
      <Breadcrumbs
        list={[
          { label: "Organization", uri: `/${orgAccountId}` },
          { label: "Structure", uri: `/${orgAccountId}/structure` }
        ]}
      />
      <h1 className="title">Organizational Units</h1>
      {loading && <>Loading</>}

      {!loading && data?.organizationalUnitByName && (
        <>
          <ul>
            {data?.organizationalUnitByName?.children?.map(ou => (
              <div className="box">
                <Unit ou={ou} push={pushAccount} />
              </div>
            ))}
          </ul>
        </>
      )}

      {!loading && (
        <>
          <hr />
          <h1 className="title is-size-6">Accounts in root OU</h1>
          <div className="box">
            <table className="table is-striped is-fullwidth is-clickable">
              <thead>
                <tr>
                  <th>Account name</th>
                  <th>AWS Account ID</th>
                  <th>Alias</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody></tbody>
              {data?.organizationalUnitByName?.accounts?.map(acc => (
                <SingleAccount account={acc} push={pushAccount} />
              ))}
            </table>
          </div>
        </>
      )}
    </div>
  );
};
