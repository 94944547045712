/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React, { useMemo, useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { useBlueprintsQuery, Maybe, Blueprint } from "../../generated/graphql";
import { Arrow, Date } from "../../components/UI";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

type TParams = { orgId: string };

const BlueprintsByName = (
  blueprints: Array<Maybe<Pick<Blueprint, "name">>> | undefined | null
) =>
  blueprints
    ? blueprints.reduce((byName: any, bl: any) => {
        if (bl?.name && byName[bl.name]) {
          byName[bl.name].push(bl);
          return byName;
        }
        byName[bl.name] = [bl];
        return byName;
      }, {})
    : {};

interface BlueprintVersionsProps {
  blueprints: Array<any>;
  orgAccountId: string;
  push: any;
}

const BlueprintVersions = ({
  blueprints,
  orgAccountId,
  push
}: BlueprintVersionsProps) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      {blueprints.length === 1 && (
        <tr
          onClick={e => {
            e.preventDefault();
            push(
              `/${orgAccountId}/blueprints/${encodeURIComponent(
                blueprints[0].name
              )}/${encodeURIComponent(blueprints[0].version)}`
            );
          }}
        >
          <th></th>
          {<th>{blueprints[0]?.name}</th>}
          <td>
            <Link
              to={`/${orgAccountId}/blueprints/${encodeURIComponent(
                blueprints[0].name
              )}/${encodeURIComponent(blueprints[0].version)}`}
            >
              {blueprints[0]?.version}
            </Link>
          </td>

          <td>
            <Date>{blueprints[0]?.createdAt}</Date>
          </td>
          <td>
            <Date>{blueprints[0]?.updatedAt}</Date>
          </td>
        </tr>
      )}
      {blueprints.length > 1 && (
        <tr
          className={`expandable`}
          onClick={e => {
            e.preventDefault();

            setShowAll(!showAll);
          }}
        >
          <th>
            <a href="#t">
              <Arrow down={showAll} />
            </a>
          </th>
          <th>{blueprints[0]?.name}</th>
          <td colSpan={3}>{blueprints.length} versions</td>
        </tr>
      )}
      {blueprints.length > 1 &&
        showAll &&
        blueprints.map((template: any, index: number) => (
          <>
            <tr
              onClick={e => {
                e.preventDefault();

                push(
                  `/${orgAccountId}/blueprints/${encodeURIComponent(
                    template.name
                  )}/${encodeURIComponent(template.version)}`
                );
              }}
            >
              <th></th>
              <th></th>
              <td>
                <Link
                  to={`/${orgAccountId}/blueprints/${encodeURIComponent(
                    template.name
                  )}/${encodeURIComponent(template.version)}`}
                >
                  {template?.version}
                </Link>
              </td>
              <td>
                <Date>{template?.createdAt}</Date>
              </td>
              <td>
                <Date>{template?.updatedAt}</Date>
              </td>
            </tr>
          </>
        ))}
    </>
  );
};

export const Blueprints = ({
  match,
  history
}: RouteComponentProps<TParams>) => {
  const { push } = history;
  const { orgId: orgAccountId } = match.params;
  const { data, loading } = useBlueprintsQuery({
    variables: { orgAccountId }
  });

  const filtered = useMemo(
    () => (data?.blueprints ? BlueprintsByName(data?.blueprints) : {}),
    [data]
  );

  return (
    <div>
      <Breadcrumbs
        list={[
          { label: "Organization", uri: `/${orgAccountId}` },
          { label: "Blueprints", uri: `/${orgAccountId}/blueprints` }
        ]}
      />
      <h1 className="title">Blueprints</h1>
      {loading && <>Loading</>}

      {!loading && data?.blueprints && (
        <div className="box">
          <table className="table is-fullwidth is-striped is-clickable">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Version</th>
                <th>Created at</th>
                <th>Updated at</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(filtered).map(blName => {
                const blueprints = filtered[blName];
                return (
                  <BlueprintVersions
                    orgAccountId={orgAccountId}
                    blueprints={blueprints.sort(
                      (a: any, b: any) => b.version - a.version
                    )}
                    push={push}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
