import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type Account = {
  __typename?: 'Account';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  awsAccountId?: Maybe<Scalars['String']>;
  organizationalUnitId?: Maybe<Scalars['String']>;
  organizationalUnit?: Maybe<OrganizationalUnitSelector>;
  accountAlias?: Maybe<Scalars['String']>;
  creationStatus?: Maybe<AccountCreationStatus>;
  deployments?: Maybe<Array<AccountDeployment>>;
  access?: Maybe<Array<AccountAccess>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
  tags?: Maybe<Array<KeyValuePair>>;
  scannedTags?: Maybe<Array<KeyValuePair>>;
};

export type AccountAccess = {
  __typename?: 'AccountAccess';
  roleArn: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
};

export type AccountAccessInput = {
  roleArn: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
};

export type AccountCreationStatus = {
  __typename?: 'AccountCreationStatus';
  status: Scalars['String'];
  error?: Maybe<Scalars['String']>;
};

export type AccountDeployment = {
  __typename?: 'AccountDeployment';
  name: Scalars['String'];
};

export type AccountInput = {
  name: Scalars['String'];
  organizationalUnitId?: Maybe<Scalars['String']>;
  organizationalUnit?: Maybe<OrganizationalUnitSelectorInput>;
  email: Scalars['String'];
  awsAccountId?: Maybe<Scalars['String']>;
  accountAlias?: Maybe<Scalars['String']>;
  accountRoleName?: Maybe<Scalars['String']>;
  access?: Maybe<Array<AccountAccessInput>>;
  metadata?: Maybe<Array<MetadataInput>>;
  tags?: Maybe<Array<KeyValuePairInput>>;
};

export type AccountSelector = {
  __typename?: 'AccountSelector';
  name?: Maybe<Scalars['String']>;
  awsAccountId?: Maybe<Scalars['String']>;
};

export type AccountSelectorInput = {
  name?: Maybe<Scalars['String']>;
  awsAccountId?: Maybe<Scalars['String']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['String'];
  name: Scalars['String'];
  secret: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<Metadata>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ApiKeyInput = {
  name: Scalars['String'];
  metadata?: Maybe<Array<MetadataInput>>;
};

export type ApiKeyToken = {
  __typename?: 'ApiKeyToken';
  apiKeyToken: Scalars['String'];
  orgAccountId?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
};

export type AzureDeployment = {
  __typename?: 'AzureDeployment';
  name: Scalars['String'];
  provisionings: Array<AzureDeploymentProvisioning>;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  status: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  apiVersion?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  timeVersion?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<Metadata>>;
};

export type AzureDeploymentInput = {
  name: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  provisionings: Array<AzureProvisioningInput>;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<MetadataInput>>;
  apiVersion?: Maybe<Scalars['String']>;
  dryRun?: Maybe<Scalars['Boolean']>;
};

export type AzureDeploymentProvisioning = {
  __typename?: 'AzureDeploymentProvisioning';
  name: Scalars['String'];
  dependsOn?: Maybe<Array<Scalars['String']>>;
  blueprint: BlueprintSelector;
  preHooks?: Maybe<Array<DeploymentHookDefinition>>;
  postHooks?: Maybe<Array<DeploymentHookDefinition>>;
  postHooksOnFail?: Maybe<Array<DeploymentHookDefinition>>;
  settings: AzureDeploymentSettings;
  templateSettings?: Maybe<Array<AzureDeploymentTemplateSettings>>;
  state: AzureDeploymentProvisioningState;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type AzureDeploymentProvisioningState = {
  __typename?: 'AzureDeploymentProvisioningState';
  status: Scalars['String'];
  jobId: Scalars['String'];
  templates?: Maybe<Array<AzureTemplateState>>;
  preHooks?: Maybe<Array<HookState>>;
  postHooks?: Maybe<Array<HookState>>;
};

export enum AzureDeploymentScopeType {
  Tenant = 'Tenant',
  ResourceGroup = 'ResourceGroup',
  ManagementGroup = 'ManagementGroup',
  Subscription = 'Subscription'
}

export type AzureDeploymentSettings = {
  __typename?: 'AzureDeploymentSettings';
  parameters?: Maybe<Array<AzureDeploymentSettingsParameter>>;
  tags?: Maybe<Array<KeyValuePair>>;
  scope?: Maybe<Scope>;
  deploymentMode?: Maybe<Scalars['String']>;
  deploymentLocation?: Maybe<Scalars['String']>;
};

export type AzureDeploymentSettingsParameter = {
  __typename?: 'AzureDeploymentSettingsParameter';
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  valueFrom?: Maybe<ValueFrom>;
  type: ParameterType;
};

export type AzureDeploymentTemplateSettings = {
  __typename?: 'AzureDeploymentTemplateSettings';
  templateName: Scalars['String'];
  parameters?: Maybe<Array<AzureDeploymentSettingsParameter>>;
  tags?: Maybe<Array<KeyValuePair>>;
  exports?: Maybe<Array<Export>>;
  scope?: Maybe<Scope>;
  deploymentMode?: Maybe<Scalars['String']>;
  deploymentLocation?: Maybe<Scalars['String']>;
};

export type AzureProvisioningInput = {
  name: Scalars['String'];
  blueprint: BlueprintSelectorInput;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  preHooks?: Maybe<Array<ProvisioningHookInput>>;
  postHooks?: Maybe<Array<ProvisioningHookInput>>;
  postHooksOnFail?: Maybe<Array<ProvisioningHookInput>>;
  settings: AzureProvisioningSettingsInput;
  templateSettings?: Maybe<Array<AzureProvisioningTemplateSettingsInput>>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type AzureProvisioningSettingsInput = {
  parameters?: Maybe<Array<AzureSettingsParameterInput>>;
  tags?: Maybe<Array<KeyValuePairInput>>;
  scope?: Maybe<ScopeInput>;
  deploymentMode?: Maybe<Scalars['String']>;
  deploymentLocation?: Maybe<Scalars['String']>;
};

export type AzureProvisioningTemplateSettingsInput = {
  templateName: Scalars['String'];
  parameters?: Maybe<Array<AzureSettingsParameterInput>>;
  tags?: Maybe<Array<KeyValuePairInput>>;
  exports?: Maybe<Array<ExportInput>>;
  scope?: Maybe<ScopeInput>;
  deploymentMode?: Maybe<Scalars['String']>;
  deploymentLocation?: Maybe<Scalars['String']>;
};

export type AzureSettingsParameterInput = {
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  valueFrom?: Maybe<ValueFromInput>;
  type: ParameterType;
};

export type AzureSubscription = {
  __typename?: 'AzureSubscription';
  id?: Maybe<Scalars['String']>;
  azureId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  azureName?: Maybe<Scalars['String']>;
  offerType?: Maybe<AzureSubscriptionOfferType>;
  billingSettings?: Maybe<AzureSubscriptionBillingSettings>;
  managementGroup: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  processingStatus?: Maybe<ProcessingStatus>;
  tags?: Maybe<Array<KeyValuePair>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type AzureSubscriptionBillingSettings = {
  __typename?: 'AzureSubscriptionBillingSettings';
  accountName: Scalars['String'];
  profileName: Scalars['String'];
  invoiceSection: Scalars['String'];
};

export type AzureSubscriptionBillingSettingsInput = {
  accountName: Scalars['String'];
  profileName: Scalars['String'];
  invoiceSection: Scalars['String'];
};

export type AzureSubscriptionInput = {
  name: Scalars['String'];
  offerType: AzureSubscriptionOfferType;
  billingSettings?: Maybe<AzureSubscriptionBillingSettingsInput>;
  managementGroup?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<KeyValuePairInput>>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export enum AzureSubscriptionOfferType {
  Standard = 'Standard',
  DevTest = 'DevTest'
}

export enum AzureSubscriptionType {
  Ea = 'EA',
  Csp = 'CSP'
}

export type AzureTemplateState = {
  __typename?: 'AzureTemplateState';
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  errorMsg?: Maybe<Scalars['String']>;
  scopeName?: Maybe<Scalars['String']>;
  outputs?: Maybe<StackOutput>;
};

export type Blueprint = {
  __typename?: 'Blueprint';
  name: Scalars['String'];
  version: Scalars['String'];
  templates?: Maybe<Array<BlueprintTemplate>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type BlueprintDependsOnInput = {
  name: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type BlueprintInput = {
  name: Scalars['String'];
  version: Scalars['String'];
  templates?: Maybe<Array<BlueprintTemplateInput>>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type BlueprintSelector = {
  __typename?: 'BlueprintSelector';
  name: Scalars['String'];
  version: Scalars['String'];
};

export type BlueprintSelectorInput = {
  name: Scalars['String'];
  version: Scalars['String'];
};

export type BlueprintTemplate = {
  __typename?: 'BlueprintTemplate';
  name: Scalars['String'];
  version: Scalars['String'];
  dependsOn?: Maybe<Array<BlueprintTemplateDependency>>;
};

export type BlueprintTemplateDependency = {
  __typename?: 'BlueprintTemplateDependency';
  name: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type BlueprintTemplateInput = {
  name: Scalars['String'];
  version: Scalars['String'];
  dependsOn?: Maybe<Array<BlueprintDependsOnInput>>;
  type?: Maybe<TemplateType>;
  source?: Maybe<Scalars['String']>;
  regionPolicy?: Maybe<Scalars['String']>;
};

export type Deployment = {
  __typename?: 'Deployment';
  name: Scalars['String'];
  provisionings: Array<DeploymentProvisioning>;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  status: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  apiVersion?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  timeVersion?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<Metadata>>;
  driftStatus?: Maybe<Scalars['String']>;
};

export type DeploymentController = {
  __typename?: 'DeploymentController';
  name: Scalars['String'];
  coreAccounts?: Maybe<Array<DeploymentControllerCoreAccount>>;
  target: DeploymentControllerTarget;
  defaultParameters?: Maybe<Array<DeploymentSettingsParameter>>;
  state?: Maybe<Array<DeploymentControllerState>>;
  status?: Maybe<DeploymentControllerStatus>;
  regions: Array<Scalars['String']>;
  tags?: Maybe<Array<KeyValuePair>>;
  dependsOn?: Maybe<Array<DeploymentControllerDependency>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  timeVersion?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<Metadata>>;
};

export type DeploymentControllerCoreAccount = {
  __typename?: 'DeploymentControllerCoreAccount';
  name: Scalars['String'];
  account: AccountSelector;
  blueprint?: Maybe<BlueprintSelector>;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  preHooks?: Maybe<Array<DeploymentHookDefinition>>;
  postHooks?: Maybe<Array<DeploymentHookDefinition>>;
  postHooksOnFail?: Maybe<Array<DeploymentHookDefinition>>;
  settings?: Maybe<DeploymentControllerSettings>;
  templateSettings?: Maybe<Array<DeploymentTemplateSettings>>;
  /** @deprecated Use settings.parameters instead */
  parameters?: Maybe<Array<DeploymentSettingsParameter>>;
  /** @deprecated Use settings.regions instead */
  regions?: Maybe<Array<Scalars['String']>>;
};

export type DeploymentControllerCoreAccountInput = {
  name: Scalars['String'];
  account: AccountSelectorInput;
  blueprint?: Maybe<BlueprintSelectorInput>;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  preHooks?: Maybe<Array<ProvisioningHookInput>>;
  postHooks?: Maybe<Array<ProvisioningHookInput>>;
  postHooksOnFail?: Maybe<Array<ProvisioningHookInput>>;
  settings?: Maybe<DeploymentControllerSettingsInput>;
  templateSettings?: Maybe<Array<ProvisioningTemplateSettingsInput>>;
  parameters?: Maybe<Array<SettingsParameterInput>>;
  regions?: Maybe<Array<Scalars['String']>>;
};

export type DeploymentControllerDependency = {
  __typename?: 'DeploymentControllerDependency';
  name: Scalars['String'];
  type: DeploymentControllerDependencyType;
};

export type DeploymentControllerDependencyInput = {
  name: Scalars['String'];
  type: DeploymentControllerDependencyType;
};

export enum DeploymentControllerDependencyType {
  Deployment = 'Deployment',
  DeploymentController = 'DeploymentController'
}

export type DeploymentControllerInput = {
  name: Scalars['String'];
  coreAccounts?: Maybe<Array<DeploymentControllerCoreAccountInput>>;
  target: DeploymentControllerTargetInput;
  defaultParameters?: Maybe<Array<SettingsParameterInput>>;
  regions: Array<Scalars['String']>;
  dependsOn?: Maybe<Array<DeploymentControllerDependencyInput>>;
  tags?: Maybe<Array<KeyValuePairInput>>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type DeploymentControllerSettings = {
  __typename?: 'DeploymentControllerSettings';
  regions?: Maybe<Array<Scalars['String']>>;
  parameters?: Maybe<Array<DeploymentSettingsParameter>>;
  tags?: Maybe<Array<KeyValuePair>>;
  stackPolicy?: Maybe<Scalars['String']>;
  stackTerminationProtection?: Maybe<Scalars['String']>;
};

export type DeploymentControllerSettingsInput = {
  regions?: Maybe<Array<Scalars['String']>>;
  parameters?: Maybe<Array<SettingsParameterInput>>;
  tags?: Maybe<Array<KeyValuePairInput>>;
  stackPolicy?: Maybe<Scalars['String']>;
  stackTerminationProtection?: Maybe<Scalars['String']>;
};

export type DeploymentControllerState = {
  __typename?: 'DeploymentControllerState';
  deploymentName?: Maybe<Scalars['String']>;
  targetAccountName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeploymentControllerStatus = {
  __typename?: 'DeploymentControllerStatus';
  status?: Maybe<Scalars['String']>;
  deploymentsStatus?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type DeploymentControllerTarget = {
  __typename?: 'DeploymentControllerTarget';
  accounts?: Maybe<Array<AccountSelector>>;
  tags?: Maybe<Array<KeyValuePair>>;
  organizationalUnits?: Maybe<Array<OrganizationalUnitSelector>>;
  blueprint: BlueprintSelector;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  preHooks?: Maybe<Array<DeploymentHookDefinition>>;
  postHooks?: Maybe<Array<DeploymentHookDefinition>>;
  postHooksOnFail?: Maybe<Array<DeploymentHookDefinition>>;
  settings?: Maybe<DeploymentControllerSettings>;
  templateSettings?: Maybe<Array<DeploymentTemplateSettings>>;
  /** @deprecated Use settings.parameters instead */
  parameters?: Maybe<Array<DeploymentSettingsParameter>>;
  /** @deprecated Use settings.regions instead */
  regions?: Maybe<Array<Scalars['String']>>;
};

export type DeploymentControllerTargetInput = {
  accounts?: Maybe<Array<AccountSelectorInput>>;
  tags?: Maybe<Array<KeyValuePairInput>>;
  organizationalUnits?: Maybe<Array<OrganizationalUnitSelectorInput>>;
  blueprint: BlueprintSelectorInput;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  preHooks?: Maybe<Array<ProvisioningHookInput>>;
  postHooks?: Maybe<Array<ProvisioningHookInput>>;
  postHooksOnFail?: Maybe<Array<ProvisioningHookInput>>;
  settings?: Maybe<DeploymentControllerSettingsInput>;
  templateSettings?: Maybe<Array<ProvisioningTemplateSettingsInput>>;
  parameters?: Maybe<Array<SettingsParameterInput>>;
  regions?: Maybe<Array<Scalars['String']>>;
};

export type DeploymentHookDefinition = {
  __typename?: 'DeploymentHookDefinition';
  name: Scalars['String'];
  source: Scalars['String'];
  exports?: Maybe<Array<Export>>;
  parameters?: Maybe<Array<HookParameter>>;
};

export type DeploymentInput = {
  name: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  provisionings: Array<ProvisioningInput>;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Array<MetadataInput>>;
  apiVersion?: Maybe<Scalars['String']>;
  dryRun?: Maybe<Scalars['Boolean']>;
};

export type DeploymentProvisioning = {
  __typename?: 'DeploymentProvisioning';
  name: Scalars['String'];
  account: AccountSelector;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  blueprint: BlueprintSelector;
  preHooks?: Maybe<Array<DeploymentHookDefinition>>;
  postHooks?: Maybe<Array<DeploymentHookDefinition>>;
  postHooksOnFail?: Maybe<Array<DeploymentHookDefinition>>;
  defaultSettings: DeploymentSettings;
  templateSettings?: Maybe<Array<DeploymentTemplateSettings>>;
  state: DeploymentProvisioningState;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type DeploymentProvisioningState = {
  __typename?: 'DeploymentProvisioningState';
  status: Scalars['String'];
  jobId: Scalars['String'];
  templates?: Maybe<Array<TemplateState>>;
  preHooks?: Maybe<Array<HookState>>;
  postHooks?: Maybe<Array<HookState>>;
};

export type DeploymentSettings = {
  __typename?: 'DeploymentSettings';
  regions: Array<Scalars['String']>;
  parameters?: Maybe<Array<DeploymentSettingsParameter>>;
  tags?: Maybe<Array<KeyValuePair>>;
  stackPolicy?: Maybe<Scalars['String']>;
  stackTerminationProtection?: Maybe<Scalars['String']>;
};

export type DeploymentSettingsParameter = {
  __typename?: 'DeploymentSettingsParameter';
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  valueFrom?: Maybe<ValueFrom>;
  type: ParameterType;
  region?: Maybe<Scalars['String']>;
};

export type DeploymentTemplateSettings = {
  __typename?: 'DeploymentTemplateSettings';
  templateName: Scalars['String'];
  regions?: Maybe<Array<Scalars['String']>>;
  parameters?: Maybe<Array<DeploymentSettingsParameter>>;
  tags?: Maybe<Array<KeyValuePair>>;
  stackPolicy?: Maybe<Scalars['String']>;
  stackTerminationProtection?: Maybe<Scalars['String']>;
  stackPrefix?: Maybe<Scalars['String']>;
  stackName?: Maybe<Scalars['String']>;
  exports?: Maybe<Array<Export>>;
};

export type DriftDetector = {
  __typename?: 'DriftDetector';
  id: Scalars['String'];
  name: Scalars['String'];
  orgAccountId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  schedule: Scalars['String'];
  lastExecutionDate?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<Metadata>>;
};

export type DriftDetectorInput = {
  name: Scalars['String'];
  schedule: Scalars['String'];
  metadata?: Maybe<Array<MetadataInput>>;
};

export type Export = {
  __typename?: 'Export';
  name: Scalars['String'];
  output: Scalars['String'];
};

export type ExportInput = {
  name: Scalars['String'];
  output: Scalars['String'];
};

export type HookParameter = {
  __typename?: 'HookParameter';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  valueFrom?: Maybe<ValueFrom>;
};

export type HookParameterInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  valueFrom?: Maybe<ValueFromInput>;
};

export type HookState = {
  __typename?: 'HookState';
  name: Scalars['String'];
  status: Scalars['String'];
  errorMsg?: Maybe<Scalars['String']>;
  outputs?: Maybe<Array<KeyValuePair>>;
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type KeyValuePairInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ManagementGroup = {
  __typename?: 'ManagementGroup';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  azureName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  subscriptions?: Maybe<Array<AzureSubscription>>;
  parentName?: Maybe<Scalars['String']>;
  children?: Maybe<Array<ManagementGroup>>;
  status?: Maybe<ProcessingStatus>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type ManagementGroupInput = {
  name: Scalars['String'];
  parentName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type Metadata = {
  __typename?: 'Metadata';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type MetadataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  organization?: Maybe<Organization>;
  removeOrganization?: Maybe<Scalars['Boolean']>;
  organizationalUnit?: Maybe<OrganizationalUnit>;
  removeOrganizationalUnit?: Maybe<Scalars['Boolean']>;
  account?: Maybe<Account>;
  removeAccount?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use commonTemplate instead */
  template?: Maybe<Template>;
  /** @deprecated Use removeCommonTemplate instead */
  removeTemplate?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use commonBlueprint instead */
  blueprint?: Maybe<Blueprint>;
  /** @deprecated Use removeCommonBlueprint instead */
  removeBlueprint?: Maybe<Scalars['Boolean']>;
  parameter?: Maybe<Parameter>;
  removeParameter?: Maybe<Scalars['Boolean']>;
  deployment: Deployment;
  removeDeployment?: Maybe<Scalars['Boolean']>;
  deploymentController?: Maybe<DeploymentController>;
  removeDeploymentController?: Maybe<Scalars['Boolean']>;
  organizationController?: Maybe<OrganizationController>;
  removeOrganizationController?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  removeRole?: Maybe<Scalars['Boolean']>;
  roleBinding?: Maybe<RoleBinding>;
  removeRoleBinding?: Maybe<Scalars['Boolean']>;
  driftDetector?: Maybe<DriftDetector>;
  removeDriftDetector?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<ApiKey>;
  removeApiKey?: Maybe<Scalars['Boolean']>;
  sync?: Maybe<SyncResponse>;
  tenant?: Maybe<Tenant>;
  removeTenant?: Maybe<Scalars['Boolean']>;
  managementGroup?: Maybe<ManagementGroup>;
  removeManagementGroup?: Maybe<Scalars['Boolean']>;
  azureDeployment: AzureDeployment;
  removeAzureDeployment?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<AzureSubscription>;
  removeSubscription?: Maybe<Scalars['Boolean']>;
  resourceGroup?: Maybe<ResourceGroup>;
  removeResourceGroup?: Maybe<Scalars['Boolean']>;
  rbacRole?: Maybe<Role>;
  removeRbacRole?: Maybe<Scalars['Boolean']>;
  rbacRoleBinding?: Maybe<RoleBinding>;
  removeRbacRoleBinding?: Maybe<Scalars['Boolean']>;
  rbacApiKey?: Maybe<ApiKey>;
  removeRbacApiKey?: Maybe<Scalars['Boolean']>;
  tenantController?: Maybe<TenantController>;
  removeTenantController?: Maybe<Scalars['Boolean']>;
  commonTemplate?: Maybe<Template>;
  removeCommonTemplate?: Maybe<Scalars['Boolean']>;
  commonBlueprint?: Maybe<Blueprint>;
  removeCommonBlueprint?: Maybe<Scalars['Boolean']>;
  commonParameter?: Maybe<Parameter>;
  removeCommonParameter?: Maybe<Scalars['Boolean']>;
};


export type MutationOrganizationArgs = {
  input: OrganizationInput;
};


export type MutationRemoveOrganizationArgs = {
  name: Scalars['String'];
};


export type MutationOrganizationalUnitArgs = {
  orgAccountId: Scalars['String'];
  input: OrganizationalUnitInput;
};


export type MutationRemoveOrganizationalUnitArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationAccountArgs = {
  orgAccountId: Scalars['String'];
  input: AccountInput;
};


export type MutationRemoveAccountArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationTemplateArgs = {
  orgAccountId: Scalars['String'];
  input?: Maybe<TemplateInput>;
};


export type MutationRemoveTemplateArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};


export type MutationBlueprintArgs = {
  orgAccountId: Scalars['String'];
  input: BlueprintInput;
};


export type MutationRemoveBlueprintArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};


export type MutationParameterArgs = {
  orgAccountId: Scalars['String'];
  input?: Maybe<ParameterInput>;
};


export type MutationRemoveParameterArgs = {
  orgAccountId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationDeploymentArgs = {
  orgAccountId: Scalars['String'];
  input: DeploymentInput;
};


export type MutationRemoveDeploymentArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeploymentControllerArgs = {
  orgAccountId: Scalars['String'];
  input: DeploymentControllerInput;
};


export type MutationRemoveDeploymentControllerArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationOrganizationControllerArgs = {
  orgAccountId: Scalars['String'];
  input: OrganizationControllerInput;
};


export type MutationRemoveOrganizationControllerArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRoleArgs = {
  orgAccountId: Scalars['String'];
  input: RoleInput;
};


export type MutationRemoveRoleArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRoleBindingArgs = {
  orgAccountId: Scalars['String'];
  input: RoleBindingInput;
};


export type MutationRemoveRoleBindingArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDriftDetectorArgs = {
  orgAccountId: Scalars['String'];
  input: DriftDetectorInput;
};


export type MutationRemoveDriftDetectorArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationApiKeyArgs = {
  orgAccountId: Scalars['String'];
  input: ApiKeyInput;
};


export type MutationRemoveApiKeyArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationSyncArgs = {
  orgAccountId: Scalars['String'];
};


export type MutationTenantArgs = {
  input: TenantInput;
};


export type MutationRemoveTenantArgs = {
  name: Scalars['String'];
};


export type MutationManagementGroupArgs = {
  azureTenantId: Scalars['String'];
  input: ManagementGroupInput;
};


export type MutationRemoveManagementGroupArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationAzureDeploymentArgs = {
  azureTenantId: Scalars['String'];
  input: AzureDeploymentInput;
};


export type MutationRemoveAzureDeploymentArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationSubscriptionArgs = {
  azureTenantId: Scalars['String'];
  input: AzureSubscriptionInput;
};


export type MutationRemoveSubscriptionArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationResourceGroupArgs = {
  azureTenantId: Scalars['String'];
  input: ResourceGroupInput;
};


export type MutationRemoveResourceGroupArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRbacRoleArgs = {
  rootId: Scalars['String'];
  input: RbacRoleInput;
};


export type MutationRemoveRbacRoleArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRbacRoleBindingArgs = {
  rootId: Scalars['String'];
  input: RbacRoleBindingInput;
};


export type MutationRemoveRbacRoleBindingArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRbacApiKeyArgs = {
  rootId: Scalars['String'];
  input: RbacApiKeyInput;
};


export type MutationRemoveRbacApiKeyArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationTenantControllerArgs = {
  azureTenantId: Scalars['String'];
  input: TenantControllerInput;
};


export type MutationRemoveTenantControllerArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCommonTemplateArgs = {
  rootId: Scalars['String'];
  input?: Maybe<TemplateInput>;
};


export type MutationRemoveCommonTemplateArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};


export type MutationCommonBlueprintArgs = {
  rootId: Scalars['String'];
  input: BlueprintInput;
};


export type MutationRemoveCommonBlueprintArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};


export type MutationCommonParameterArgs = {
  rootId: Scalars['String'];
  input?: Maybe<ParameterInput>;
};


export type MutationRemoveCommonParameterArgs = {
  rootId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  rootOuId: Scalars['String'];
  access: Array<AccountAccess>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type OrganizationalUnit = {
  __typename?: 'OrganizationalUnit';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  accounts?: Maybe<Array<Account>>;
  parent?: Maybe<OrganizationalUnitSelector>;
  children?: Maybe<Array<Maybe<OrganizationalUnit>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type OrganizationalUnitInput = {
  parent: OrganizationalUnitSelectorInput;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  metadata?: Maybe<Array<MetadataInput>>;
};

export type OrganizationalUnitSelector = {
  __typename?: 'OrganizationalUnitSelector';
  name?: Maybe<Scalars['String']>;
  organizationalUnitId?: Maybe<Scalars['String']>;
  recursive?: Maybe<Scalars['Boolean']>;
};

export type OrganizationalUnitSelectorInput = {
  name?: Maybe<Scalars['String']>;
  organizationUnitId?: Maybe<Scalars['String']>;
  recursive?: Maybe<Scalars['Boolean']>;
};

export type OrganizationController = {
  __typename?: 'OrganizationController';
  id: Scalars['String'];
  name: Scalars['String'];
  defaultAccountRoles?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<OrganizationControllerSchedule>;
  state?: Maybe<OrganizationControllerState>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type OrganizationControllerInput = {
  name: Scalars['String'];
  defaultAccountRoles?: Maybe<Array<Scalars['String']>>;
  schedule?: Maybe<OrganizationControllerScheduleInput>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type OrganizationControllerSchedule = {
  __typename?: 'OrganizationControllerSchedule';
  every: Scalars['String'];
};

export type OrganizationControllerScheduleInput = {
  every: Scalars['String'];
};

export type OrganizationControllerState = {
  __typename?: 'OrganizationControllerState';
  lastExecutionDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMsg?: Maybe<Scalars['String']>;
  scannedAccounts?: Maybe<Scalars['Int']>;
  scannedOrganizationalUnits?: Maybe<Scalars['Int']>;
};

export type OrganizationInput = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  access: Array<AccountAccessInput>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type Parameter = {
  __typename?: 'Parameter';
  name: Scalars['String'];
  value: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  secret: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type ParameterInput = {
  name: Scalars['String'];
  value: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  secret: Scalars['Boolean'];
  metadata?: Maybe<Array<MetadataInput>>;
};

export enum ParameterType {
  Json = 'JSON',
  Text = 'TEXT',
  Bool = 'BOOL',
  Int = 'INT',
  Undefined = 'UNDEFINED'
}

export type PresignUrl = {
  __typename?: 'PresignUrl';
  url: Scalars['String'];
};

export type ProcessingStatus = {
  __typename?: 'ProcessingStatus';
  state?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export enum Provider {
  Aws = 'AWS',
  Azure = 'AZURE'
}

export type ProvisioningHookInput = {
  name: Scalars['String'];
  source: Scalars['String'];
  exports?: Maybe<Array<ExportInput>>;
  parameters?: Maybe<Array<HookParameterInput>>;
};

export type ProvisioningInput = {
  name: Scalars['String'];
  account: AccountSelectorInput;
  blueprint: BlueprintSelectorInput;
  dependsOn?: Maybe<Array<Scalars['String']>>;
  preHooks?: Maybe<Array<ProvisioningHookInput>>;
  postHooks?: Maybe<Array<ProvisioningHookInput>>;
  postHooksOnFail?: Maybe<Array<ProvisioningHookInput>>;
  defaultSettings: ProvisioningSettingsInput;
  templateSettings?: Maybe<Array<ProvisioningTemplateSettingsInput>>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type ProvisioningSettingsInput = {
  regions: Array<Scalars['String']>;
  parameters?: Maybe<Array<SettingsParameterInput>>;
  tags?: Maybe<Array<KeyValuePairInput>>;
  stackPolicy?: Maybe<Scalars['String']>;
  stackTerminationProtection?: Maybe<Scalars['String']>;
};

export type ProvisioningTemplateSettingsInput = {
  templateName: Scalars['String'];
  regions?: Maybe<Array<Scalars['String']>>;
  parameters?: Maybe<Array<SettingsParameterInput>>;
  tags?: Maybe<Array<KeyValuePairInput>>;
  stackPolicy?: Maybe<Scalars['String']>;
  stackTerminationProtection?: Maybe<Scalars['String']>;
  stackName?: Maybe<Scalars['String']>;
  stackPrefix?: Maybe<Scalars['String']>;
  exports?: Maybe<Array<ExportInput>>;
};

export type Query = {
  __typename?: 'Query';
  organizations?: Maybe<Array<Maybe<Organization>>>;
  organization?: Maybe<Organization>;
  organizationByName?: Maybe<Organization>;
  accessibleOrganizations: Array<Maybe<Organization>>;
  organizationalUnits?: Maybe<Array<Maybe<OrganizationalUnit>>>;
  organizationalUnit?: Maybe<OrganizationalUnit>;
  organizationalUnitByName?: Maybe<OrganizationalUnit>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  account?: Maybe<Account>;
  accountByName?: Maybe<Account>;
  accountByAccountId?: Maybe<Account>;
  /** @deprecated Use commonTemplates instead */
  templates?: Maybe<Array<Maybe<Template>>>;
  /** @deprecated Use commonTemplate instead */
  template?: Maybe<Template>;
  /** @deprecated Use commonBlueprints instead */
  blueprints?: Maybe<Array<Maybe<Blueprint>>>;
  /** @deprecated Use commonBlueprint instead */
  blueprint?: Maybe<Blueprint>;
  deployments?: Maybe<Array<Deployment>>;
  deploymentByName: Deployment;
  lastDeployment?: Maybe<Deployment>;
  parameters: Array<Parameter>;
  parameter?: Maybe<Parameter>;
  deploymentControllers?: Maybe<Array<Maybe<DeploymentController>>>;
  deploymentController?: Maybe<DeploymentController>;
  organizationControllers?: Maybe<Array<Maybe<OrganizationController>>>;
  organizationController?: Maybe<OrganizationController>;
  roles?: Maybe<Array<Maybe<Role>>>;
  role?: Maybe<Role>;
  roleBindings?: Maybe<Array<Maybe<RoleBinding>>>;
  roleBinding?: Maybe<RoleBinding>;
  driftDetectors?: Maybe<Array<Maybe<DriftDetector>>>;
  driftDetector?: Maybe<DriftDetector>;
  apiKeys?: Maybe<Array<ApiKey>>;
  apiKey?: Maybe<ApiKey>;
  /** @deprecated Use commonTemplateGenerateGetPresignUrl instead */
  templateGenerateGetPresignUrl?: Maybe<PresignUrl>;
  /** @deprecated Use commonTemplateGeneratePutPresignUrl instead */
  templateGeneratePutPresignUrl?: Maybe<PresignUrl>;
  /** @deprecated Use commonTemplateGenerateSignedBodyUrl instead */
  templateGenerateSignedBodyUrl?: Maybe<TemplateBodyUrl>;
  /** @deprecated Use commonTemplateGenerateDynamicBodyHelp instead */
  templateGenerateDynamicBodyHelp?: Maybe<TemplateHelp>;
  commonTemplateGenerateGetPresignUrl?: Maybe<PresignUrl>;
  commonTemplateGeneratePutPresignUrl?: Maybe<PresignUrl>;
  commonTemplateGenerateSignedBodyUrl?: Maybe<TemplateBodyUrl>;
  commonTemplateGenerateDynamicBodyHelp?: Maybe<TemplateHelp>;
  getApiKeyToken: ApiKeyToken;
  /** @deprecated Delegated to RBAC OPA service will be removed in a future release */
  validateApiKeyToken: ApiKeyToken;
  latestReleasedVersion?: Maybe<ReleasedVersion>;
  tenants?: Maybe<Array<Maybe<Tenant>>>;
  tenant?: Maybe<Tenant>;
  tenantByName?: Maybe<Tenant>;
  accessibleTenants: Array<Maybe<Tenant>>;
  managementGroups?: Maybe<Array<Maybe<ManagementGroup>>>;
  managementGroup?: Maybe<ManagementGroup>;
  managementGroupByName?: Maybe<ManagementGroup>;
  azureDeployments?: Maybe<Array<AzureDeployment>>;
  azureDeploymentByName: AzureDeployment;
  subscriptions?: Maybe<Array<Maybe<AzureSubscription>>>;
  subscription?: Maybe<AzureSubscription>;
  subscriptionByName?: Maybe<AzureSubscription>;
  tenantControllers?: Maybe<Array<Maybe<TenantController>>>;
  tenantController?: Maybe<TenantController>;
  resourceGroups?: Maybe<Array<Maybe<ResourceGroup>>>;
  resourceGroup?: Maybe<ResourceGroup>;
  resourceGroupByName?: Maybe<ResourceGroup>;
  rbacRoles?: Maybe<Array<Maybe<Role>>>;
  rbacRole?: Maybe<Role>;
  rbacRoleBindings?: Maybe<Array<Maybe<RoleBinding>>>;
  rbacRoleBinding?: Maybe<RoleBinding>;
  rbacApiKeys?: Maybe<Array<ApiKey>>;
  rbacApiKey?: Maybe<ApiKey>;
  commonBlueprints?: Maybe<Array<Maybe<Blueprint>>>;
  commonBlueprint?: Maybe<Blueprint>;
  commonTemplates?: Maybe<Array<Maybe<Template>>>;
  commonTemplate?: Maybe<Template>;
  commonParameters: Array<Parameter>;
  commonParameter?: Maybe<Parameter>;
  accessibleRoots: Array<Maybe<Root>>;
};


export type QueryOrganizationArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryOrganizationByNameArgs = {
  name: Scalars['String'];
};


export type QueryOrganizationalUnitsArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryOrganizationalUnitArgs = {
  orgAccountId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryOrganizationalUnitByNameArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryAccountsArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryAccountArgs = {
  orgAccountId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryAccountByNameArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryAccountByAccountIdArgs = {
  orgAccountId: Scalars['String'];
  awsAccountId: Scalars['String'];
};


export type QueryTemplatesArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryTemplateArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};


export type QueryBlueprintsArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryBlueprintArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};


export type QueryDeploymentsArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryDeploymentByNameArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryLastDeploymentArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryParametersArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryParameterArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryDeploymentControllersArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryDeploymentControllerArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryOrganizationControllersArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryOrganizationControllerArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryRolesArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryRoleArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryRoleBindingsArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryRoleBindingArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryDriftDetectorsArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryDriftDetectorArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryApiKeysArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryApiKeyArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryTemplateGenerateGetPresignUrlArgs = {
  orgAccountId: Scalars['String'];
  fileName: Scalars['String'];
};


export type QueryTemplateGeneratePutPresignUrlArgs = {
  orgAccountId: Scalars['String'];
  fileName: Scalars['String'];
};


export type QueryTemplateGenerateSignedBodyUrlArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};


export type QueryTemplateGenerateDynamicBodyHelpArgs = {
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};


export type QueryCommonTemplateGenerateGetPresignUrlArgs = {
  rootId: Scalars['String'];
  fileName: Scalars['String'];
};


export type QueryCommonTemplateGeneratePutPresignUrlArgs = {
  rootId: Scalars['String'];
  fileName: Scalars['String'];
};


export type QueryCommonTemplateGenerateSignedBodyUrlArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};


export type QueryCommonTemplateGenerateDynamicBodyHelpArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};


export type QueryGetApiKeyTokenArgs = {
  orgAccountId: Scalars['String'];
};


export type QueryTenantArgs = {
  azureTenantId: Scalars['String'];
};


export type QueryTenantByNameArgs = {
  name: Scalars['String'];
};


export type QueryManagementGroupsArgs = {
  azureTenantId: Scalars['String'];
};


export type QueryManagementGroupArgs = {
  azureTenantId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryManagementGroupByNameArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryAzureDeploymentsArgs = {
  azureTenantId: Scalars['String'];
};


export type QueryAzureDeploymentByNameArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type QuerySubscriptionsArgs = {
  azureTenantId: Scalars['String'];
};


export type QuerySubscriptionArgs = {
  azureTenantId: Scalars['String'];
  id: Scalars['String'];
};


export type QuerySubscriptionByNameArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryTenantControllersArgs = {
  azureTenantId: Scalars['String'];
};


export type QueryTenantControllerArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryResourceGroupsArgs = {
  azureTenantId: Scalars['String'];
};


export type QueryResourceGroupArgs = {
  azureTenantId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryResourceGroupByNameArgs = {
  azureTenantId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryRbacRolesArgs = {
  rootId: Scalars['String'];
};


export type QueryRbacRoleArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryRbacRoleBindingsArgs = {
  rootId: Scalars['String'];
};


export type QueryRbacRoleBindingArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryRbacApiKeysArgs = {
  rootId: Scalars['String'];
};


export type QueryRbacApiKeyArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
};


export type QueryCommonBlueprintsArgs = {
  rootId: Scalars['String'];
};


export type QueryCommonBlueprintArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};


export type QueryCommonTemplatesArgs = {
  rootId: Scalars['String'];
};


export type QueryCommonTemplateArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};


export type QueryCommonParametersArgs = {
  rootId: Scalars['String'];
};


export type QueryCommonParameterArgs = {
  rootId: Scalars['String'];
  name: Scalars['String'];
};

export type RbacApiKeyInput = {
  name: Scalars['String'];
  metadata?: Maybe<Array<MetadataInput>>;
};

export type RbacRoleBindingInput = {
  name: Scalars['String'];
  subjects?: Maybe<Array<RoleBindingSubjectInput>>;
  roleRef: RoleBindingRefInput;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type RbacRoleInput = {
  name: Scalars['String'];
  rules: Array<RoleRuleInput>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type ReleasedVersion = {
  __typename?: 'ReleasedVersion';
  version?: Maybe<Scalars['String']>;
  releaseNotesURL?: Maybe<Scalars['String']>;
};

export type ResourceGroup = {
  __typename?: 'ResourceGroup';
  name: Scalars['String'];
  azureName?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  subscription: Scalars['String'];
  tags?: Maybe<Array<KeyValuePair>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type ResourceGroupInput = {
  name: Scalars['String'];
  location: Scalars['String'];
  subscription: Scalars['String'];
  tags?: Maybe<Array<KeyValuePairInput>>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['String'];
  name: Scalars['String'];
  rules: Array<RoleRule>;
  metadata?: Maybe<Array<Metadata>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type RoleBinding = {
  __typename?: 'RoleBinding';
  id: Scalars['String'];
  name: Scalars['String'];
  subjects?: Maybe<Array<RoleBindingSubject>>;
  roleRef: RoleBindingRef;
  metadata?: Maybe<Array<Metadata>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type RoleBindingInput = {
  name: Scalars['String'];
  subjects?: Maybe<Array<RoleBindingSubjectInput>>;
  roleRef: RoleBindingRefInput;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type RoleBindingRef = {
  __typename?: 'RoleBindingRef';
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type RoleBindingRefInput = {
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type RoleBindingSubject = {
  __typename?: 'RoleBindingSubject';
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type RoleBindingSubjectInput = {
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type RoleInput = {
  name: Scalars['String'];
  rules: Array<RoleRuleInput>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type RoleRule = {
  __typename?: 'RoleRule';
  resources: Array<Scalars['String']>;
  resourceNames?: Maybe<Array<Scalars['String']>>;
  actions: Array<Scalars['String']>;
  conditions?: Maybe<Array<RoleRuleCondition>>;
};

export type RoleRuleCondition = {
  __typename?: 'RoleRuleCondition';
  resource: Scalars['String'];
  resourceNames: Array<Scalars['String']>;
  recursive: Scalars['Boolean'];
};

export type RoleRuleConditionInput = {
  resource: Scalars['String'];
  resourceNames: Array<Scalars['String']>;
  recursive: Scalars['Boolean'];
};

export type RoleRuleInput = {
  resources: Array<Scalars['String']>;
  resourceNames?: Maybe<Array<Scalars['String']>>;
  actions: Array<Scalars['String']>;
  conditions?: Maybe<Array<RoleRuleConditionInput>>;
};

export type Root = {
  __typename?: 'Root';
  rootId: Scalars['String'];
  provider: Provider;
  name: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type Scope = {
  __typename?: 'Scope';
  type: AzureDeploymentScopeType;
  names?: Maybe<Array<Scalars['String']>>;
  subscription?: Maybe<Scalars['String']>;
};

export type ScopeInput = {
  type: AzureDeploymentScopeType;
  names?: Maybe<Array<Scalars['String']>>;
  subscription?: Maybe<Scalars['String']>;
};

export type SettingsParameterInput = {
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  valueFrom?: Maybe<ValueFromInput>;
  type: ParameterType;
  region?: Maybe<Scalars['String']>;
};

export type StackOutput = {
  __typename?: 'StackOutput';
  outputKey: Scalars['String'];
  outputValue?: Maybe<Scalars['String']>;
  exportName?: Maybe<Scalars['String']>;
};

export type SyncResponse = {
  __typename?: 'SyncResponse';
  success: Scalars['Boolean'];
};

export type Template = {
  __typename?: 'Template';
  name: Scalars['String'];
  version: Scalars['String'];
  type: TemplateType;
  source: Scalars['String'];
  regionPolicy?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type TemplateBodyUrl = {
  __typename?: 'TemplateBodyUrl';
  bodyUrl: Scalars['String'];
};

export type TemplateHelp = {
  __typename?: 'TemplateHelp';
  help?: Maybe<Scalars['String']>;
};

export type TemplateInput = {
  name: Scalars['String'];
  version: Scalars['String'];
  type: TemplateType;
  source: Scalars['String'];
  regionPolicy?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type TemplateState = {
  __typename?: 'TemplateState';
  name: Scalars['String'];
  region: Scalars['String'];
  stackName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMsg?: Maybe<Scalars['String']>;
  driftStatus?: Maybe<Scalars['String']>;
  outputs?: Maybe<Array<StackOutput>>;
};

export enum TemplateType {
  LambdaArn = 'LAMBDA_ARN',
  Local = 'LOCAL',
  S3Url = 'S3_URL',
  AzureStorageUrl = 'AZURE_STORAGE_URL'
}

export type Tenant = {
  __typename?: 'Tenant';
  azureTenantId: Scalars['String'];
  servicePrincipalId: Scalars['String'];
  name: Scalars['String'];
  subscriptionsType: AzureSubscriptionType;
  customerId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type TenantController = {
  __typename?: 'TenantController';
  id: Scalars['String'];
  name: Scalars['String'];
  schedule?: Maybe<TenantControllerSchedule>;
  state?: Maybe<TenantControllerState>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  metadata?: Maybe<Array<Metadata>>;
};

export type TenantControllerInput = {
  name: Scalars['String'];
  schedule?: Maybe<TenantControllerScheduleInput>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type TenantControllerSchedule = {
  __typename?: 'TenantControllerSchedule';
  every: Scalars['String'];
};

export type TenantControllerScheduleInput = {
  every: Scalars['String'];
};

export type TenantControllerState = {
  __typename?: 'TenantControllerState';
  lastExecutionDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMsg?: Maybe<Scalars['String']>;
  scannedSubscriptions?: Maybe<Scalars['Int']>;
  scannedResourceGroups?: Maybe<Scalars['Int']>;
  scannedMgmtGroups?: Maybe<Scalars['Int']>;
};

export type TenantInput = {
  azureTenantId: Scalars['String'];
  servicePrincipalId: Scalars['String'];
  servicePrincipalPassword: Scalars['String'];
  name: Scalars['String'];
  subscriptionsType: AzureSubscriptionType;
  customerId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<MetadataInput>>;
};

export type ValueFrom = {
  __typename?: 'ValueFrom';
  parameter?: Maybe<Scalars['String']>;
  export?: Maybe<Scalars['String']>;
  deploymentName?: Maybe<Scalars['String']>;
  deploymentControllerName?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  scopeName?: Maybe<Scalars['String']>;
  scopeType?: Maybe<Scalars['String']>;
};

export type ValueFromInput = {
  parameter?: Maybe<Scalars['String']>;
  export?: Maybe<Scalars['String']>;
  deploymentName?: Maybe<Scalars['String']>;
  deploymentControllerName?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  scopeName?: Maybe<Scalars['String']>;
  scopeType?: Maybe<Scalars['String']>;
};

export type AccountQueryVariables = Exact<{
  orgAccountId: Scalars['String'];
  id: Scalars['String'];
}>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { accountByAccountId?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name' | 'email' | 'awsAccountId' | 'accountAlias' | 'createdAt' | 'updatedAt'>
    & { organizationalUnit?: Maybe<(
      { __typename?: 'OrganizationalUnitSelector' }
      & Pick<OrganizationalUnitSelector, 'name'>
    )>, creationStatus?: Maybe<(
      { __typename?: 'AccountCreationStatus' }
      & Pick<AccountCreationStatus, 'status' | 'error'>
    )>, access?: Maybe<Array<(
      { __typename?: 'AccountAccess' }
      & Pick<AccountAccess, 'roleArn' | 'externalId'>
    )>>, metadata?: Maybe<Array<(
      { __typename?: 'Metadata' }
      & Pick<Metadata, 'key' | 'value'>
    )>> }
  )> }
);

export type BlueprintQueryVariables = Exact<{
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
}>;


export type BlueprintQuery = (
  { __typename?: 'Query' }
  & { blueprint?: Maybe<(
    { __typename?: 'Blueprint' }
    & Pick<Blueprint, 'name' | 'version' | 'createdAt' | 'updatedAt'>
    & { templates?: Maybe<Array<(
      { __typename?: 'BlueprintTemplate' }
      & Pick<BlueprintTemplate, 'name' | 'version'>
      & { dependsOn?: Maybe<Array<(
        { __typename?: 'BlueprintTemplateDependency' }
        & Pick<BlueprintTemplateDependency, 'name' | 'version'>
      )>> }
    )>>, metadata?: Maybe<Array<(
      { __typename?: 'Metadata' }
      & Pick<Metadata, 'key' | 'value'>
    )>> }
  )> }
);

export type BlueprintsQueryVariables = Exact<{
  orgAccountId: Scalars['String'];
}>;


export type BlueprintsQuery = (
  { __typename?: 'Query' }
  & { blueprints?: Maybe<Array<Maybe<(
    { __typename?: 'Blueprint' }
    & Pick<Blueprint, 'name' | 'version' | 'createdAt' | 'updatedAt'>
    & { templates?: Maybe<Array<(
      { __typename?: 'BlueprintTemplate' }
      & Pick<BlueprintTemplate, 'name' | 'version'>
      & { dependsOn?: Maybe<Array<(
        { __typename?: 'BlueprintTemplateDependency' }
        & Pick<BlueprintTemplateDependency, 'version' | 'name'>
      )>> }
    )>>, metadata?: Maybe<Array<(
      { __typename?: 'Metadata' }
      & Pick<Metadata, 'key' | 'value'>
    )>> }
  )>>> }
);

export type DeploymentsQueryVariables = Exact<{
  orgAccountId: Scalars['String'];
}>;


export type DeploymentsQuery = (
  { __typename?: 'Query' }
  & { deployments?: Maybe<Array<(
    { __typename?: 'Deployment' }
    & Pick<Deployment, 'name' | 'status'>
    & { provisionings: Array<(
      { __typename?: 'DeploymentProvisioning' }
      & Pick<DeploymentProvisioning, 'name'>
      & { account: (
        { __typename?: 'AccountSelector' }
        & Pick<AccountSelector, 'awsAccountId' | 'name'>
      ), state: (
        { __typename?: 'DeploymentProvisioningState' }
        & Pick<DeploymentProvisioningState, 'status'>
      ), blueprint: (
        { __typename?: 'BlueprintSelector' }
        & Pick<BlueprintSelector, 'name' | 'version'>
      ) }
    )> }
  )>> }
);

export type OrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationsQuery = (
  { __typename?: 'Query' }
  & { accessibleOrganizations: Array<Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'name' | 'orgAccountId' | 'rootOuId'>
  )>> }
);

export type OrganizationalUnitsQueryVariables = Exact<{
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
}>;


export type OrganizationalUnitsQuery = (
  { __typename?: 'Query' }
  & { organizationalUnitByName?: Maybe<(
    { __typename?: 'OrganizationalUnit' }
    & Pick<OrganizationalUnit, 'id' | 'name'>
    & { accounts?: Maybe<Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'email' | 'awsAccountId' | 'accountAlias'>
      & { creationStatus?: Maybe<(
        { __typename?: 'AccountCreationStatus' }
        & Pick<AccountCreationStatus, 'status' | 'error'>
      )> }
    )>>, children?: Maybe<Array<Maybe<(
      { __typename?: 'OrganizationalUnit' }
      & Pick<OrganizationalUnit, 'id' | 'name'>
      & { accounts?: Maybe<Array<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name' | 'email' | 'awsAccountId' | 'accountAlias'>
        & { creationStatus?: Maybe<(
          { __typename?: 'AccountCreationStatus' }
          & Pick<AccountCreationStatus, 'status' | 'error'>
        )> }
      )>>, children?: Maybe<Array<Maybe<(
        { __typename?: 'OrganizationalUnit' }
        & Pick<OrganizationalUnit, 'id' | 'name'>
        & { accounts?: Maybe<Array<(
          { __typename?: 'Account' }
          & Pick<Account, 'id' | 'name' | 'email' | 'awsAccountId' | 'accountAlias'>
          & { creationStatus?: Maybe<(
            { __typename?: 'AccountCreationStatus' }
            & Pick<AccountCreationStatus, 'status' | 'error'>
          )> }
        )>>, children?: Maybe<Array<Maybe<(
          { __typename?: 'OrganizationalUnit' }
          & Pick<OrganizationalUnit, 'id' | 'name'>
          & { accounts?: Maybe<Array<(
            { __typename?: 'Account' }
            & Pick<Account, 'id' | 'name' | 'email' | 'awsAccountId' | 'accountAlias'>
            & { creationStatus?: Maybe<(
              { __typename?: 'AccountCreationStatus' }
              & Pick<AccountCreationStatus, 'status' | 'error'>
            )> }
          )>> }
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type TemplateQueryVariables = Exact<{
  orgAccountId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
}>;


export type TemplateQuery = (
  { __typename?: 'Query' }
  & { template?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'name' | 'version' | 'type' | 'source' | 'regionPolicy' | 'createdAt' | 'updatedAt'>
    & { metadata?: Maybe<Array<(
      { __typename?: 'Metadata' }
      & Pick<Metadata, 'key' | 'value'>
    )>> }
  )> }
);

export type TemplatesQueryVariables = Exact<{
  orgAccountId: Scalars['String'];
}>;


export type TemplatesQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<Array<Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'name' | 'version' | 'createdAt' | 'updatedAt'>
  )>>> }
);


export const AccountDocument = gql`
    query Account($orgAccountId: String!, $id: String!) {
  accountByAccountId(orgAccountId: $orgAccountId, awsAccountId: $id) {
    id
    name
    email
    awsAccountId
    organizationalUnit {
      name
    }
    accountAlias
    creationStatus {
      status
      error
    }
    access {
      roleArn
      externalId
    }
    createdAt
    updatedAt
    metadata {
      key
      value
    }
  }
}
    `;
export type AccountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AccountQuery, AccountQueryVariables>, 'query'> & ({ variables: AccountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AccountComponent = (props: AccountComponentProps) => (
      <ApolloReactComponents.Query<AccountQuery, AccountQueryVariables> query={AccountDocument} {...props} />
    );
    
export type AccountProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AccountQuery, AccountQueryVariables>
    } & TChildProps;
export function withAccount<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AccountQuery,
  AccountQueryVariables,
  AccountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AccountQuery, AccountQueryVariables, AccountProps<TChildProps, TDataName>>(AccountDocument, {
      alias: 'account',
      ...operationOptions
    });
};

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      orgAccountId: // value for 'orgAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
      }
export function useAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, baseOptions);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = ApolloReactCommon.QueryResult<AccountQuery, AccountQueryVariables>;
export const BlueprintDocument = gql`
    query Blueprint($orgAccountId: String!, $name: String!, $version: String!) {
  blueprint(orgAccountId: $orgAccountId, name: $name, version: $version) {
    name
    version
    templates {
      name
      version
      dependsOn {
        name
        version
      }
    }
    createdAt
    updatedAt
    metadata {
      key
      value
    }
  }
}
    `;
export type BlueprintComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BlueprintQuery, BlueprintQueryVariables>, 'query'> & ({ variables: BlueprintQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BlueprintComponent = (props: BlueprintComponentProps) => (
      <ApolloReactComponents.Query<BlueprintQuery, BlueprintQueryVariables> query={BlueprintDocument} {...props} />
    );
    
export type BlueprintProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BlueprintQuery, BlueprintQueryVariables>
    } & TChildProps;
export function withBlueprint<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BlueprintQuery,
  BlueprintQueryVariables,
  BlueprintProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BlueprintQuery, BlueprintQueryVariables, BlueprintProps<TChildProps, TDataName>>(BlueprintDocument, {
      alias: 'blueprint',
      ...operationOptions
    });
};

/**
 * __useBlueprintQuery__
 *
 * To run a query within a React component, call `useBlueprintQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlueprintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlueprintQuery({
 *   variables: {
 *      orgAccountId: // value for 'orgAccountId'
 *      name: // value for 'name'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useBlueprintQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BlueprintQuery, BlueprintQueryVariables>) {
        return ApolloReactHooks.useQuery<BlueprintQuery, BlueprintQueryVariables>(BlueprintDocument, baseOptions);
      }
export function useBlueprintLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlueprintQuery, BlueprintQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BlueprintQuery, BlueprintQueryVariables>(BlueprintDocument, baseOptions);
        }
export type BlueprintQueryHookResult = ReturnType<typeof useBlueprintQuery>;
export type BlueprintLazyQueryHookResult = ReturnType<typeof useBlueprintLazyQuery>;
export type BlueprintQueryResult = ApolloReactCommon.QueryResult<BlueprintQuery, BlueprintQueryVariables>;
export const BlueprintsDocument = gql`
    query Blueprints($orgAccountId: String!) {
  blueprints(orgAccountId: $orgAccountId) {
    name
    templates {
      name
      dependsOn {
        version
        name
        version
      }
      version
    }
    version
    createdAt
    updatedAt
    metadata {
      key
      value
    }
  }
}
    `;
export type BlueprintsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BlueprintsQuery, BlueprintsQueryVariables>, 'query'> & ({ variables: BlueprintsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BlueprintsComponent = (props: BlueprintsComponentProps) => (
      <ApolloReactComponents.Query<BlueprintsQuery, BlueprintsQueryVariables> query={BlueprintsDocument} {...props} />
    );
    
export type BlueprintsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BlueprintsQuery, BlueprintsQueryVariables>
    } & TChildProps;
export function withBlueprints<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BlueprintsQuery,
  BlueprintsQueryVariables,
  BlueprintsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BlueprintsQuery, BlueprintsQueryVariables, BlueprintsProps<TChildProps, TDataName>>(BlueprintsDocument, {
      alias: 'blueprints',
      ...operationOptions
    });
};

/**
 * __useBlueprintsQuery__
 *
 * To run a query within a React component, call `useBlueprintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlueprintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlueprintsQuery({
 *   variables: {
 *      orgAccountId: // value for 'orgAccountId'
 *   },
 * });
 */
export function useBlueprintsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BlueprintsQuery, BlueprintsQueryVariables>) {
        return ApolloReactHooks.useQuery<BlueprintsQuery, BlueprintsQueryVariables>(BlueprintsDocument, baseOptions);
      }
export function useBlueprintsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlueprintsQuery, BlueprintsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BlueprintsQuery, BlueprintsQueryVariables>(BlueprintsDocument, baseOptions);
        }
export type BlueprintsQueryHookResult = ReturnType<typeof useBlueprintsQuery>;
export type BlueprintsLazyQueryHookResult = ReturnType<typeof useBlueprintsLazyQuery>;
export type BlueprintsQueryResult = ApolloReactCommon.QueryResult<BlueprintsQuery, BlueprintsQueryVariables>;
export const DeploymentsDocument = gql`
    query Deployments($orgAccountId: String!) {
  deployments(orgAccountId: $orgAccountId) {
    name
    provisionings {
      name
      account {
        awsAccountId
        name
      }
      state {
        status
      }
      blueprint {
        name
        version
      }
    }
    status
  }
}
    `;
export type DeploymentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DeploymentsQuery, DeploymentsQueryVariables>, 'query'> & ({ variables: DeploymentsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DeploymentsComponent = (props: DeploymentsComponentProps) => (
      <ApolloReactComponents.Query<DeploymentsQuery, DeploymentsQueryVariables> query={DeploymentsDocument} {...props} />
    );
    
export type DeploymentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DeploymentsQuery, DeploymentsQueryVariables>
    } & TChildProps;
export function withDeployments<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeploymentsQuery,
  DeploymentsQueryVariables,
  DeploymentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DeploymentsQuery, DeploymentsQueryVariables, DeploymentsProps<TChildProps, TDataName>>(DeploymentsDocument, {
      alias: 'deployments',
      ...operationOptions
    });
};

/**
 * __useDeploymentsQuery__
 *
 * To run a query within a React component, call `useDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentsQuery({
 *   variables: {
 *      orgAccountId: // value for 'orgAccountId'
 *   },
 * });
 */
export function useDeploymentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeploymentsQuery, DeploymentsQueryVariables>) {
        return ApolloReactHooks.useQuery<DeploymentsQuery, DeploymentsQueryVariables>(DeploymentsDocument, baseOptions);
      }
export function useDeploymentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeploymentsQuery, DeploymentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeploymentsQuery, DeploymentsQueryVariables>(DeploymentsDocument, baseOptions);
        }
export type DeploymentsQueryHookResult = ReturnType<typeof useDeploymentsQuery>;
export type DeploymentsLazyQueryHookResult = ReturnType<typeof useDeploymentsLazyQuery>;
export type DeploymentsQueryResult = ApolloReactCommon.QueryResult<DeploymentsQuery, DeploymentsQueryVariables>;
export const OrganizationsDocument = gql`
    query Organizations {
  accessibleOrganizations {
    name
    orgAccountId
    rootOuId
  }
}
    `;
export type OrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationsQuery, OrganizationsQueryVariables>, 'query'>;

    export const OrganizationsComponent = (props: OrganizationsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationsQuery, OrganizationsQueryVariables> query={OrganizationsDocument} {...props} />
    );
    
export type OrganizationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationsQuery, OrganizationsQueryVariables>
    } & TChildProps;
export function withOrganizations<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationsQuery,
  OrganizationsQueryVariables,
  OrganizationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationsQuery, OrganizationsQueryVariables, OrganizationsProps<TChildProps, TDataName>>(OrganizationsDocument, {
      alias: 'organizations',
      ...operationOptions
    });
};

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, baseOptions);
      }
export function useOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, baseOptions);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = ApolloReactCommon.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const OrganizationalUnitsDocument = gql`
    query OrganizationalUnits($orgAccountId: String!, $name: String!) {
  organizationalUnitByName(orgAccountId: $orgAccountId, name: $name) {
    id
    name
    accounts {
      id
      name
      email
      awsAccountId
      accountAlias
      creationStatus {
        status
        error
      }
    }
    children {
      id
      name
      accounts {
        id
        name
        email
        awsAccountId
        accountAlias
        creationStatus {
          status
          error
        }
      }
      children {
        id
        name
        accounts {
          id
          name
          email
          awsAccountId
          accountAlias
          creationStatus {
            status
            error
          }
        }
        children {
          id
          name
          accounts {
            id
            name
            email
            awsAccountId
            accountAlias
            creationStatus {
              status
              error
            }
          }
        }
      }
    }
    children {
      id
      name
      accounts {
        id
        name
        email
        awsAccountId
        accountAlias
        creationStatus {
          status
          error
        }
      }
      children {
        id
        name
        accounts {
          id
          name
          email
          awsAccountId
          accountAlias
          creationStatus {
            status
            error
          }
        }
        children {
          id
          name
          accounts {
            id
            name
            email
            awsAccountId
            accountAlias
            creationStatus {
              status
              error
            }
          }
        }
      }
    }
  }
}
    `;
export type OrganizationalUnitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables>, 'query'> & ({ variables: OrganizationalUnitsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationalUnitsComponent = (props: OrganizationalUnitsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables> query={OrganizationalUnitsDocument} {...props} />
    );
    
export type OrganizationalUnitsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables>
    } & TChildProps;
export function withOrganizationalUnits<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationalUnitsQuery,
  OrganizationalUnitsQueryVariables,
  OrganizationalUnitsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables, OrganizationalUnitsProps<TChildProps, TDataName>>(OrganizationalUnitsDocument, {
      alias: 'organizationalUnits',
      ...operationOptions
    });
};

/**
 * __useOrganizationalUnitsQuery__
 *
 * To run a query within a React component, call `useOrganizationalUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationalUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationalUnitsQuery({
 *   variables: {
 *      orgAccountId: // value for 'orgAccountId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganizationalUnitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables>(OrganizationalUnitsDocument, baseOptions);
      }
export function useOrganizationalUnitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables>(OrganizationalUnitsDocument, baseOptions);
        }
export type OrganizationalUnitsQueryHookResult = ReturnType<typeof useOrganizationalUnitsQuery>;
export type OrganizationalUnitsLazyQueryHookResult = ReturnType<typeof useOrganizationalUnitsLazyQuery>;
export type OrganizationalUnitsQueryResult = ApolloReactCommon.QueryResult<OrganizationalUnitsQuery, OrganizationalUnitsQueryVariables>;
export const TemplateDocument = gql`
    query Template($orgAccountId: String!, $name: String!, $version: String!) {
  template(orgAccountId: $orgAccountId, name: $name, version: $version) {
    name
    version
    type
    source
    regionPolicy
    createdAt
    updatedAt
    metadata {
      key
      value
    }
  }
}
    `;
export type TemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TemplateQuery, TemplateQueryVariables>, 'query'> & ({ variables: TemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TemplateComponent = (props: TemplateComponentProps) => (
      <ApolloReactComponents.Query<TemplateQuery, TemplateQueryVariables> query={TemplateDocument} {...props} />
    );
    
export type TemplateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TemplateQuery, TemplateQueryVariables>
    } & TChildProps;
export function withTemplate<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TemplateQuery,
  TemplateQueryVariables,
  TemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TemplateQuery, TemplateQueryVariables, TemplateProps<TChildProps, TDataName>>(TemplateDocument, {
      alias: 'template',
      ...operationOptions
    });
};

/**
 * __useTemplateQuery__
 *
 * To run a query within a React component, call `useTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuery({
 *   variables: {
 *      orgAccountId: // value for 'orgAccountId'
 *      name: // value for 'name'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, baseOptions);
      }
export function useTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateQuery, TemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplateQuery, TemplateQueryVariables>(TemplateDocument, baseOptions);
        }
export type TemplateQueryHookResult = ReturnType<typeof useTemplateQuery>;
export type TemplateLazyQueryHookResult = ReturnType<typeof useTemplateLazyQuery>;
export type TemplateQueryResult = ApolloReactCommon.QueryResult<TemplateQuery, TemplateQueryVariables>;
export const TemplatesDocument = gql`
    query Templates($orgAccountId: String!) {
  templates(orgAccountId: $orgAccountId) {
    name
    version
    createdAt
    updatedAt
  }
}
    `;
export type TemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TemplatesQuery, TemplatesQueryVariables>, 'query'> & ({ variables: TemplatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TemplatesComponent = (props: TemplatesComponentProps) => (
      <ApolloReactComponents.Query<TemplatesQuery, TemplatesQueryVariables> query={TemplatesDocument} {...props} />
    );
    
export type TemplatesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TemplatesQuery, TemplatesQueryVariables>
    } & TChildProps;
export function withTemplates<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TemplatesQuery,
  TemplatesQueryVariables,
  TemplatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TemplatesQuery, TemplatesQueryVariables, TemplatesProps<TChildProps, TDataName>>(TemplatesDocument, {
      alias: 'templates',
      ...operationOptions
    });
};

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *      orgAccountId: // value for 'orgAccountId'
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, baseOptions);
      }
export function useTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, baseOptions);
        }
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = ApolloReactCommon.QueryResult<TemplatesQuery, TemplatesQueryVariables>;