/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { Maybe } from "../../generated/graphql";
import { Link } from "react-router-dom";
interface BreadcrumbsList {
  label: string;
  uri: Maybe<string>;
}

interface BreadcrumbsListProps {
  list: Array<BreadcrumbsList>;
}

export const Breadcrumbs = ({ list }: BreadcrumbsListProps) => (
  <nav className="breadcrumb is-small" aria-label="breadcrumbs">
    <ul>
      {list.map(br => (
        <li>
          <Link to={br.uri || ""}>{br.label}</Link>
        </li>
      ))}
    </ul>
  </nav>
);
