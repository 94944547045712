/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";

interface ArrowProps {
  down: boolean;
}

export const Arrow = ({ down }: ArrowProps) =>
  down ? (
    <i className="fa fa-angle-down"></i>
  ) : (
    <i className="fa fa-angle-right"></i>
  );
