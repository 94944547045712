/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTemplateQuery } from "../../generated/graphql";
import { Date } from "../../components/UI";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Meta } from "../../components/Meta/Meta";

type TParams = { orgId: string; name: string; version: string };

export const Template = ({ match }: RouteComponentProps<TParams>) => {
  const { orgId: orgAccountId, name, version } = match.params;
  const { data, loading } = useTemplateQuery({
    variables: {
      orgAccountId,
      name: decodeURIComponent(name),
      version: decodeURIComponent(version)
    }
  });

  return (
    <div>
      {loading && <>Loading</>}
      <Breadcrumbs
        list={[
          { label: "Organization", uri: `/${orgAccountId}` },
          { label: "Templates", uri: `/${orgAccountId}/templates` },
          { label: `${data?.template?.name}`, uri: `` },
          { label: `${data?.template?.version}`, uri: `` }
        ]}
      />
      <h1 className="title">{data?.template?.name}</h1>
      <h1 className="subtitle">
        Version: <span className="tag is-large">{data?.template?.version}</span>
      </h1>

      <div className="box">
        <table className="table is-fullwidth">
          <tbody>
            <tr>
              <th>Type</th>
              <td>
                <span className="tag">{data?.template?.type}</span>
              </td>
            </tr>
            <tr>
              <th>Source</th>
              <td className="is-size-6">{data?.template?.source || <>-</>}</td>
            </tr>
            <tr>
              <th>Region policy</th>
              <td>{data?.template?.regionPolicy || <>-</>}</td>
            </tr>
            <tr>
              <th>Created at</th>
              <td>
                <Date>{data?.template?.createdAt || ""}</Date>
              </td>
            </tr>
            <tr>
              <th>Updated at</th>
              <td>
                <Date>{data?.template?.updatedAt || ""}</Date>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {data?.template?.metadata && <Meta metadata={data?.template?.metadata} />}
    </div>
  );
};
