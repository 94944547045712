/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Metadata } from "../../generated/graphql";

interface MetaProps {
  metadata: Array<Metadata>;
}

export const Meta = ({ metadata }: MetaProps) => {
  return (
    <>
      <h2 className="title is-size-5">Meta</h2>
      <div className="box">
        <table className="table is-fullwidth">
          {metadata.map(meta => (
            <tr>
              <th>{meta.key}</th>
              <td>
                <CopyToClipboard text={`${meta.value}`}>
                  <span className="clipboard">{meta.value}</span>
                </CopyToClipboard>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};
